import * as React from "react";
import FCMSendDeviceMessageForm from "../form/FCMSendDeviceMessageForm";
import { Form, onChangePayload } from "../../../components/Form/hoc/withForm";
import { NotificationFormField } from "../form/element/NotificationElement";
import { TokenField } from "../form/element/TokenElement";
import useAPI from "hooks/API";

type FieldTypes = TokenField | NotificationFormField;

type FormType = Form<FieldTypes>;

export default function SendDeviceMessage({onSubmit}:any) {
  const api = useAPI();
  const onSubmitForm = (form: FormType) => {
    api.post("/admin/firebaseMessage", {
      ...form,
      devices:(form.token as string[] ?? []).map((val)=>{
        const [deviceId,token] = val.split("::")
        return {deviceId,token}
      }),
      sendType: "DEVICES",
    }).then(()=>{
      onSubmit && onSubmit();
    });
  }
  const onChange = ({ form, key, value }: onChangePayload<FieldTypes>) =>
    console.log(form, key, value);
  const isDisabled = (form: FormType) => {
    return !(form.body && form.title && form.token);
  };

  return (
    <FCMSendDeviceMessageForm
      onChange={onChange}
      onSubmit={onSubmitForm}
      submitButtonLabel="Send"
      isDisabled={isDisabled}
    />
  );
}
