import * as React from "react";
import useAPIWithState, { IFormElementProps, IOptionComponentWrapperProps, OptionElement } from "../../../../components/Form/Element/OptionElement";
import withKey from "../../../../components/Form/hoc/withKey";
import useAPI from "hooks/API";

export enum TokenField {
  TOKEN = "token",
}

function withTopic(Component:React.FC<IFormElementProps>){
  return withKey<TokenField,IOptionComponentWrapperProps<TokenField>>(Component,TokenField.TOKEN);
}

function DeviceElementView(props:IFormElementProps){
  const api = useAPI();
  const { data } = useAPIWithState(async () =>
    api.get(`/admin/firebaseDevicesWithSize?page=0&size=100000`)
  );
  return <OptionElement {...props} options={(data?.data ?? []).map(({deviceId,token}:any)=>({label:deviceId,value:deviceId + "::" + token}))}/>
}

export const DeviceElement = withTopic(DeviceElementView);



