import {Route, Switch, useLocation} from "react-router-dom";
import LoginPage from "../Login";
import LogoutPage from "../Logout";
import HomePage from "../Home";
import ReportUsersPage from "../ReportUsers";
import ReportSubscriptionsPage from "../ReportSubscriptions";
import ReportDevicesPage from "../ReportDevices";
import ReportUserMessagesPage from "../ReportUserMessages";
import AssetsPage from "../Assets";
import AssetAddPage from "../Assets/new";
import ParitiesPage from "../Parities";
import ParityDetailsPage from "../Parities/detail";
import AssetDetailPage from "../Assets/detail";
import UserDetailPage from "../Users/detail";
import SourceChangesPage from "../SourceChanges";
import CMCChangesPage from "../CMCChanges";
import UsersListingPage from "pages/Users";
import React from "react";
import {Redirect} from "react-router";
import ConfigsPage from "pages/Config";
import PortfolioPage from "pages/Users/portfolio";
import AppViewPage from "pages/Users/appview";
import UserComparePage from "pages/Users/compare";
import Find from "pages/Find/Find";
import FirebasePage from "pages/Firebase";

interface RoutesProps {
  isAuth: boolean
}

const Routes = ({isAuth}: RoutesProps) => {
  const location = useLocation();

  if (!isAuth && location.pathname !== "/auth/login") return <Redirect to={{pathname: '/auth/login'}}/>;

  return <Switch>
    <Route path="/auth/login" component={LoginPage} />
    <Route path="/auth/logout" component={LogoutPage} />
    <Route path="/report/users" exact component={ReportUsersPage} />
    <Route path="/report/subscriptions" exact component={ReportSubscriptionsPage} />
    <Route path="/report/devices" exact component={ReportDevicesPage} />
    <Route path="/report/messages" exact component={ReportUserMessagesPage} />
    <Route path="/source/changes" exact component={SourceChangesPage} />
    <Route path="/cmc/changes" exact component={CMCChangesPage} />
    <Route path="/assets" exact component={AssetsPage} />
    <Route path="/assets/new" exact component={AssetAddPage} />
    <Route path="/assets/:id" exact component={AssetDetailPage} />
    <Route path="/parities" exact component={ParitiesPage} />
    <Route path="/parities/:id" exact component={ParityDetailsPage} />
    <Route path="/users/:userId" exact component={UserDetailPage} />
    <Route path="/users/:userId/compare/:otherUserId" exact component={UserComparePage} />
    <Route path="/users/:userId/:deviceId/appview" exact component={AppViewPage} />
    <Route path="/users" exact component={UsersListingPage} />
    <Route path="/configs" exact component={ConfigsPage} />
    <Route path="/portfolios/:id" exact component={PortfolioPage} />
    <Route path={["/firebase","/firebase/*"]} exact component={FirebasePage}/>
    <Route path ={["/find","/find/*"]} component={Find}/>
    <Route path="/" component={HomePage} />
  </Switch>
}

export default Routes;
