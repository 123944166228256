import * as React from "react";
import useAPIWithState, {
  IFormElementProps,
  IOptionComponentWrapperProps,
  OptionElement,
  SelectMode,
} from "../../../../components/Form/Element/OptionElement";
import withKey, {
  IFormComponentProps,
} from "../../../../components/Form/hoc/withKey";
import useAPI from "hooks/API";
import withPlaceHolder from "components/Form/hoc/withPlaceholder";
import TextAreaWrapper from "components/Form/Element/TextAreaElement";

export enum TopicField {
  TOPIC = "topics",
}

function withTopic(Component: React.FC<IFormElementProps>) {
  return withKey<TopicField, IOptionComponentWrapperProps<TopicField>>(
    Component,
    TopicField.TOPIC
  );
}

const topics = [
  { label: "Topic 1", value: "topic 1" },
  { label: "Topic 2", value: "topic 2" },
];

function TopicElementView(props: IFormElementProps) {
  //todo: infinite scroll
  const api = useAPI();
  const { data } = useAPIWithState(async () =>
    api.get(`/admin/firebaseTopics?page=0&size=1000`)
  );
  return <OptionElement {...props} options={(data?.data ?? []).map(({name}:any)=>({label:name,value:name}))} />;
}

const apps = [
  { label: "ANDROID", value: "ANDROID" },
  { label: "IOS", value: "IOS" },
  { label: "HUAWEI", value: "HUAWEI" },
];

function AppElementView(props: IFormElementProps) {
  return <OptionElement {...props} options={apps} />;
}

const TopicTextElementView =  withPlaceHolder(TextAreaWrapper, "Topic")
export const TopicTextElement = withTopic(TopicTextElementView);
export const TopicElement = withTopic(TopicElementView);
export const AppElement = withTopic(AppElementView);
