import * as React from "react";
import { AppElement, TopicElement, TopicField } from "./element/TopicElement";
import withForm, { FormComponentProps } from "../../../components/Form/hoc/withForm";
import NotificationElement, {
  NotificationFormField,
} from "./element/NotificationElement";

function FCMSendAppMessageForm(
  props: FormComponentProps<TopicField | NotificationFormField>
) {
  return (
    <>
      <AppElement {...props} mode="multiple" />
      <NotificationElement {...props} />
    </>
  );
}

export default withForm(FCMSendAppMessageForm);
