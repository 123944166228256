import * as React from "react"
import { TopicElement, TopicField } from "./element/TopicElement"
import withForm, { FormComponentProps } from "../../../components/Form/hoc/withForm"
import NotificationElement, { NotificationFormField } from "./element/NotificationElement"


function FCMSendTopicMessageForm(props:FormComponentProps<TopicField | NotificationFormField>){
    return <>
        <TopicElement {...props} mode="multiple"/>
        <NotificationElement {...props}/>
    </>
}

export default withForm(FCMSendTopicMessageForm)