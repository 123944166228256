import * as React from "react";
import { IFormElementProps } from "../Element/OptionElement";
import { formElementValue } from "./withForm";

export interface IFormComponentProps<T extends String> {
  onChangeElement: (fieldKey: T, value?: formElementValue) => void;
}

export default function withKey<
  T extends string,
  V extends IFormComponentProps<T>
>(Component: React.FC<IFormElementProps>, fieldKey: T) {
  return (props: V) => {
    const onChangeElement = (value?: formElementValue) =>
      props.onChangeElement(fieldKey, value);
    return <Component {...props} onChangeElement={onChangeElement} />;
  };
}
