import * as React from "react";
import TextArea from "antd/lib/input/TextArea";
import { IFormElementProps } from "./OptionElement";

export default function TextAreaWrapper(props: IFormElementProps) {
  return (
    <TextArea
      {...props}
      onChange={(ev) => props.onChangeElement(ev.target.value)}
    />
  );
}
