import { Button } from "antd";
import * as React from "react";

export interface onChangePayload<T extends string> {
  form: Form<T>;
  key?: T;
  value?: formElementValue;
}

export type formElementValue = string | string[];

export type onChange<T extends string> = (payload: onChangePayload<T>) => void;
export type isDisabled<T extends string> = (payload: Form<T>,isFormChanged?:()=>boolean) => boolean;

export interface IFormProps<T extends string> {
  defaultFormValues?: Form<T>;
  onChange?: onChange<T>;
  submitButtonLabel?: string;
  discardButtonLabel?: string;
  onSubmit?: (payload: Form<T>) => void;
  isDisabled?: isDisabled<T>;
}

export type Form<T extends string> = {
  [key in T]?: formElementValue;
};
export type onChangeElement<T> = (key: T, value?: formElementValue) => void;

export interface FormComponentProps<T extends string> {
  onChangeElement: onChangeElement<T>;
}

export function useForm<T extends string>(
  onChange?: onChange<T>,
  isDisabled?: isDisabled<T>,
  defaultFormValues?: Form<T>
) {
  const form = React.useRef<Form<T>>(defaultFormValues ?? {});
  const [disabled, setDisabled] = React.useState(
    isDisabled ? isDisabled(form.current) : false
  );
  const onChangeElement: onChangeElement<T> = (key: T, value) => {
    form.current[key] = value;
    onChange && onChange({ form: form.current, key, value });
    isDisabled && setDisabled(isDisabled(form.current,()=>isFormChanged()));
  };

  const isFormChanged = ()=> {
    //todo: compare default values and form values
    return true;
  }

  const discard = () => {};

  return { onChangeElement, discard, form, disabled };
}

export default function withForm<T extends string>(
  Component: React.FC<FormComponentProps<T>>
) {
  return (props: IFormProps<T>) => {
    const { onChangeElement, discard, form, disabled } = useForm(
      props.onChange,
      props.isDisabled,
      props.defaultFormValues
    );
    return (
      <>
        <Component onChangeElement={onChangeElement} />
        <Button
          onClick={() => props.onSubmit && props.onSubmit(form.current)}
          disabled={disabled}
        >
          {props.submitButtonLabel ?? "Submit"}
        </Button>
        <Button onClick={discard}>
          {props.discardButtonLabel ?? "Cancel"}
        </Button>
      </>
    );
  };
}
