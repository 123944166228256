import * as React from "react";
import { TopicElement, TopicField, TopicTextElement } from "./element/TopicElement";
import withForm, { FormComponentProps } from "../../../components/Form/hoc/withForm";
import { DeviceElement, TokenField } from "./element/TokenElement";

function FCMSubscribeDeviceForm(
  props: FormComponentProps<TopicField | TokenField>
) {
  return (
    <>
      <TopicTextElement {...props}/>
      <DeviceElement {...props} mode="multiple" />
    </>
  );
}

export default withForm(FCMSubscribeDeviceForm);
