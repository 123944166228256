import { Select, SelectProps } from "antd";
import * as React from "react";
import { IFormComponentProps } from "../hoc/withKey";
import { formElementValue } from "../hoc/withForm";
import "./OptionElement.less"
import useAPI from "hooks/API";

export interface IFormElementProps {
  placeholder?: string;
  onChangeElement: (value?: formElementValue) => void;
}

export interface IOption {
  label: string;
  value: string;
}

export type SelectMode = "multiple" | "tags";

export interface IOptionComponentWrapperProps<T extends string>
  extends IFormComponentProps<T> {
  mode?: SelectMode;
}

export interface IOptionElementProps extends IFormElementProps {
  options: IOption[];
  mode?: SelectMode;
}

export function OptionElement(props: IOptionElementProps) {
  return (
    <Select
      className="temp"
      placeholder={props.placeholder}
      onChange={(value) => props.onChangeElement(value?.toString().split(","))}
      style={{ width: "100%" ,maxHeight:"200px",overflow:"scroll"}}
      mode={props.mode}
      filterOption={true}
      optionFilterProp="label"  
      options={props.options}
    >
    </Select>
  );
}

export default function useAPIWithState(request:any,reloadRef?:any,flags=[]){
  const [data,setData] = React.useState<any>();
  React.useEffect(()=>{
    if(reloadRef)
      reloadRef.current = fetch;
  },[setData])
  React.useEffect(()=>{
    fetch();
  },[...flags])

  const fetch = ()=>request().then(setData)
  return {data};
}
