import * as React from "react";
import FCMSendAppMessage from "../form/FCMSendAppMessage";
import { Form, onChangePayload } from "../../../components/Form/hoc/withForm";
import { TopicField } from "../form/element/TopicElement";
import { NotificationFormField } from "../form/element/NotificationElement";
import useAPI from "hooks/API";

type FieldTypes = TopicField | NotificationFormField;

type FormType = Form<FieldTypes>;

export default function SendAppMessage({onSubmit}:any) {
  const api = useAPI();
  
  const onSubmitForm = (form: FormType) => {
    api.post("/admin/firebaseMessage", {
      ...form,
      sendType: "TOPICS",
    }).then(()=>{
      onSubmit && onSubmit();
    });
  };
  const onChange = ({ form, key, value }: onChangePayload<FieldTypes>) =>
    console.log(form, key, value);
  const isDisabled = (form: FormType) => {
    return !(form.body && form.title && form.topics);
  };
  return (
    <FCMSendAppMessage
      onChange={onChange}
      onSubmit={onSubmitForm}
      isDisabled={isDisabled}
      submitButtonLabel="Send"
    />
  );
}
