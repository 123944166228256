import * as React from "react";
import { ButtonModal } from "./FCMMessageTable";
import PaginationTable from "../paginationTable/PaginationTable";
import useAPI from "hooks/API";
import FCMSubscribeDevice from "../page/FCMSubscribeDevice";
import useAPIWithState from "components/Form/Element/OptionElement";
import { Button, Card, Table } from "antd";

export default function FCMTopicTable() {
  const columns = [
    {
      dataIndex: "name",
      title: "Topics",
    },
    {
      dataIndex: "tags",
      title: "Tags",
    },
    {
      dataIndex: "deviceCount",
      title:"Device Count"
    },
  ];
  const closeRef = React.useRef<any>();
  const reloadRef = React.useRef<any>();
  const api = useAPI();
  const onSubmit = () => {
    if(closeRef?.current){
      closeRef?.current()
    }
    if(reloadRef?.current){
      reloadRef?.current();
    }
  }
  return (
    <>
     <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <ButtonModal label={"Create"} title={"Create Topic"} closeRef={closeRef} >
          <FCMSubscribeDevice onSubmit={onSubmit}/>
        </ButtonModal>
      </div>
      <PaginationTable
        columns={columns}
        size={10}
        request={async ({ page, size ,searchText}: any) =>
          api.get(`/admin/firebaseTopics?page=${page}&size=${size}${searchText ? `&searchText=${searchText}` : "" }`)
        }
        reloadRef={reloadRef}
        expandable={
          {expandedRowRender: ({id}:any,...params:any) => <ExpandedTopicRow id={id}/>}}

      />
    </>
   
  );
}


function ExpandedTopicRow({ id }: any) {
  const api = useAPI();
  const { data } = useAPIWithState(async () =>
    api.get(`/admin/firebaseTopic/${id}`)
  );
  const deviceColumns = [
    {
      dataIndex: "deviceId",
      title: "Device",
    },
  ];

  const tagColumns = [
    {
      dataIndex: "deviceId",
      title: "Tags",
      render:(_:any,name:any)=> name
      
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent:"space-between",
        alignItems:"center"
      }}
      className="expanded-row"
    >
      <Table pagination={{}} style={{minWidth:"40%"}} columns={tagColumns} dataSource={data?.tags} />
      <Table pagination={{}} style={{minWidth:"40%"}} columns={deviceColumns} dataSource={data?.devices} />
    </div>
  );
}
