import SubMenu, { ISubMenuItem } from "components/SubMenu/SubMenu";
import * as React from "react";
import FCMMessageTable from "./table/FCMMessageTable";
import FCMTopicTable from "./table/FCMTopicTable";
import FCMAnalyzerTable from "./table/FCMAnalyzerTable";

export default function FCMMessages() {
  const subMenuItems: ISubMenuItem[] = [
    {
      label: "Messages",
      path: "/messages",
      Component: FCMMessageTable,
    },

    {
      label: "Topics",
      path: "/topics",
      Component: FCMTopicTable,
    },

    {
      label: "Analyzers",
      path: "/analyzers",
      Component: FCMAnalyzerTable,
    }
  ];
  return (
    <SubMenu pathPrefix="/firebase" subMenuItems={subMenuItems} />
  );
}
