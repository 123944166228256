import * as React from "react";

import TextArea from "antd/lib/input/TextArea";
import withPlaceHolder from "../../../../components/Form/hoc/withPlaceholder";
import withKey from "../../../../components/Form/hoc/withKey";
import { FormComponentProps } from "../../../../components/Form/hoc/withForm";
import { IFormElementProps } from "../../../../components/Form/Element/OptionElement";
import TextAreaWrapper from "../../../../components/Form/Element/TextAreaElement";

export enum NotificationFormField {
  TITLE = "title",
  BODY = "body",
}

const TitleElement = withKey(
  withPlaceHolder(TextAreaWrapper, "Title"),
  NotificationFormField.TITLE
);
const BodyElement = withKey(
  withPlaceHolder(TextAreaWrapper, "Body"),
  NotificationFormField.BODY
);

export default function NotificationElement(
  props: FormComponentProps<NotificationFormField>
) {
  return (
    <>
      <TitleElement {...props} />
      <BodyElement {...props} />
    </>
  );
}