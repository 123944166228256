import { Button, Card, Modal, Table, Tabs } from "antd";
import * as React from "react";
import PaginationTable from "../paginationTable/PaginationTable";
import useAPI from "hooks/API";
import SendAppMessage from "../page/SendAppMessage";
import SendDeviceMessage from "../page/SendDeviceMessage";
import SendTopicMessage from "../page/SendTopicMessage";
import useAPIWithState from "components/Form/Element/OptionElement";

export default function FCMMessageTable() {
  const columns = [
    {
      dataIndex: "title",
      title: "title",
    },
    {
      dataIndex: "body",
      title: "body",
    },
    {
      dataIndex: "topics",
      title: "topics",
      render: (topics: any) => topics?.join(","),
    },
    {
      dataIndex: "deviceCount",
      title: "Device Count",
    },
    {
      dataIndex: "createdAt",
      title: "date",
    },
  ];

  const closeRef = React.useRef<any>();
  const reloadRef = React.useRef<any>();
  const api = useAPI();
  const onSubmit = () => {
    if (closeRef?.current) {
      closeRef?.current();
    }
    if (reloadRef?.current) {
      reloadRef?.current();
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <ButtonModal label={"Send"} title={"Send Message"} closeRef={closeRef}>
          <Tabs type="card">
            <Tabs.TabPane tab="App" key="app">
              <SendAppMessage onSubmit={onSubmit} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Topic" key="topic">
              <SendTopicMessage onSubmit={onSubmit} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Device" key="device">
              <SendDeviceMessage onSubmit={onSubmit} />
            </Tabs.TabPane>
          </Tabs>
        </ButtonModal>
      </div>
      <PaginationTable
        columns={columns}
        size={10}
        request={async ({ page, size, searchText }: any) =>
          api.get(
            `/admin/firebaseMessages?page=${page}&size=${size}${
              searchText ? `&searchText=${searchText}` : ""
            }`
          )
        }
        reloadRef={reloadRef}
        expandable={{
          expandedRowRender: ({ id }: any, ...params: any) => (
            <ExpandedMessageRow id={id} />
          ),
        }}
      />
    </>
  );
}

function ExpandedMessageRow({ id }: any) {
  const api = useAPI();
  const { data } = useAPIWithState(async () =>
    api.get(`/admin/firebaseMessage/${id}`)
  );
  console.log(data);
  const columns = [
    {
      dataIndex: "deviceId",
      title: "Device",
    },
    {
      dataIndex: "sent",
      title:"ACK",
      render:(send:boolean)=>send? "ACK" : "NACK"
    }
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems:"center"
      }}
      className="expanded-row"
    >
      <div style={{ display: "flex", flexDirection: "column", minWidth:"50%" , height:"100%"}}>
        <Card style={{display:"flex",justifyContent:"center",backgroundColor:"#243665",color:"white"}}>{data?.title}</Card>
        <Card style={{display:"flex",justifyContent:"center"}}>{data?.body}</Card>
      </div>
      <Table pagination={{}} style={{minWidth:"40%"}} columns={columns} dataSource={data?.devices} />
    </div>
  );
}

export const ButtonModal = ({
  disabled,
  defaultOpen,
  label,
  title,
  children,
  closeRef,
}: any) => {
  const [isOpen, setOpen] = React.useState(defaultOpen ?? false);

  React.useLayoutEffect(() => {
    if (closeRef) closeRef.current = close;
  }, [setOpen]);
  const close = () => setOpen(false);
  const open = () => setOpen(true);

  return (
    <>
      <Button onClick={open} disabled={disabled}>
        {label}
      </Button>
      <Modal title={title} visible={isOpen} onOk={close} onCancel={close}>
        {children}
      </Modal>
    </>
  );
};
