import { Input, Pagination, Table } from "antd";
import _ from "lodash";
import * as React from "react";

export default function PaginationTable({columns,request,size,reloadRef,expandable}:any) {
  const [loading, isLoading] = React.useState(false);
  const [{data,metadata}, setProps] = React.useState({metadata:{total:0,page:1},data:[
  ]});
  React.useEffect(()=>{
    if(reloadRef)
      reloadRef.current = fetch;
    fetch();
  },[metadata?.page,setProps])
  const fetch = (searchText?:string)=>{
    console.log("fetch",searchText?.length);
    request({page:metadata?.page-1,size,searchText}).then((response:any)=>response?.metadata && response?.data && setProps({...response,metadata:{...response.metadata,page:response.metadata.page + 1}}));
  }
  const onSearch = React.useCallback(_.debounce((text:any)=>fetch(text),200),[]);
  return (
    <>
      <Input onChange={(ev)=>onSearch(ev.target.value)} placeholder="Search" />
      <Table
        loading={loading}
        columns={columns}
        rowKey="id"
        dataSource={data}
        pagination={false}
        expandable={{
          expandRowByClick:true,
          expandIcon: ()=><></>,
          ...expandable
        }}
        
      />
      <Pagination onChange={(ev)=>{setProps(prev=>({...prev,metadata:{...prev.metadata,page:ev}}))}} pageSize={size} total={metadata.total} style={{display:"flex",justifyContent:"end"}}/>
    </>
  );
}
