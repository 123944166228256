import * as React from "react";
import FCMSubscribeForm from "../form/FCMSubscribeForm";
import { TokenField } from "../form/element/TokenElement";
import { TopicField } from "../form/element/TopicElement";
import { Form, onChangePayload } from "../../../components/Form/hoc/withForm";
import useAPI from "hooks/API";

type FieldTypes = TopicField | TokenField;

type FormType = Form<FieldTypes>;

export default function FCMSubscribeDevice({onSubmit}:any) {
   const api = useAPI();
  const onSubmitForm = (form: FormType) => {
    api.post("/admin/firebaseTopic", {
      name:form.topics,
      //tags:["test"],
      devices:(form.token as string[] ?? []).map((val)=>{
        const [deviceId,token] = val.split("::")
        return {deviceId,token}
      })
  }).then(()=>{
      onSubmit && onSubmit();
    });

  };
  const onChange = ({ form, key, value }: onChangePayload<FieldTypes>) =>
    console.log(form, key, value);
  const isDisabled = (form: FormType) => {
    return !(form.token && form.topics);
  };
  return (
    <FCMSubscribeForm
      onChange={onChange}
      onSubmit={onSubmitForm}
      isDisabled={isDisabled}
      submitButtonLabel="Subscribe"
    />
  );
}
