import * as React from "react";
import withForm, { FormComponentProps } from "../../../components/Form/hoc/withForm";
import NotificationElement, {
  NotificationFormField,
} from "./element/NotificationElement";
import { DeviceElement, TokenField } from "./element/TokenElement";

function FCMSendDeviceMessageForm(
  props: FormComponentProps<TokenField | NotificationFormField>
) {
  return (
    <>
      <DeviceElement {...props} mode="multiple" />
      <NotificationElement {...props} />
    </>
  );
}

export default withForm(FCMSendDeviceMessageForm);
